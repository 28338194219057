import React from 'react';
import './Homepage.css'; // Import the CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons';

function Homepage() {
  return (
    <div className="homepage-container">
      <h1>Welcome to Nirala Eden Park</h1>
      <p>
        Society is having 6 blocks namely A to F with a total of 430 flats. It also has 8 commercial shops, two banks, and a doctor's clinic. There is a playground for adults, a park with various playing sections for kids, and a swimming pool and gym.
      </p>
      <p>
        The society also has two community halls that residents use for parties, functions, and events. Inside, there's a beautiful temple managed by the Mandir Samiti.
      </p>

      <h1>E-Mail</h1>
      <p><FontAwesomeIcon icon={faEnvelopeOpenText} style={{ color: "#0c86e4", height: "20px" }} />    office.neprwa@gmail.com</p>

      <h1>Address</h1>
      <p>Nirala Eden Park, Judges Enclave, GH 05, Main CISF Road  ,Ahinsa Khand 2, Indirapuram, Ghaziabad, Uttar Pradesh 201014</p>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7002.7383748114235!2d77.3743724913432!3d28.648662418024983!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cf1a38dcd471b%3A0x9972df67a874ed3e!2sNirala%20Eden%20Park!5e0!3m2!1sen!2sin!4v1726590713743!5m2!1sen!2sin" style={{border:"0", height:"450px", width:"100%"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title='Map-view'></iframe>
    </div>
  );
}

export default Homepage;
