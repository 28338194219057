import React from 'react';
import './Photos.css'; // Import the CSS file
import img1 from '../Images/1.jpeg';
import img2 from '../Images/2.jpeg'; 
import img3 from '../Images/3.jpeg';
import img4 from '../Images/4.jpeg';
import img5 from '../Images/5.jpeg';
import img6 from '../Images/6.jpeg';
import img7 from '../Images/7.jpeg';

import jimg1 from '../Images/J1.jpeg';
import jimg2 from '../Images/J2.jpeg'; 
import jimg3 from '../Images/J3.jpeg';
import jimg4 from '../Images/J4.jpeg';



function PhotosPage() {
  const photos = [img1, img2, img3, img4, img5, img6, img7];
  const photos2 = [jimg1, jimg2, jimg3, jimg4];
  

  return (
    <div className="photos-page">
      <header className="page-header">
        <h1>All Photos</h1>
      </header>

      <h2 className="event-heading">Independence Day 2024</h2>
      <div className="photo-grid">
        {photos.map((photo, index) => (
          <div className="photo-item" key={index}>
            <img src={photo} alt={`Photo- ${index + 1}`} />
          </div>
        ))}
      </div>

      <h2 className="event-heading">Janmashtami Celebration</h2>
      <div className="photo-grid">
        {photos2.map((photo, index) => (
          <div className="photo-item" key={index}>
            <img src={photo} alt={`Photo- ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default PhotosPage;
