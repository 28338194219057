import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
  const [isMobile, setIsMobile] = useState(false);

  return (
    <nav className="navbar">
      <Link to="/"><div className="logo">Nirala Eden Park RWA</div></Link>
      <ul className={isMobile ? "nav-links-mobile" : "nav-links"} onClick={() => setIsMobile(false)}>
        <Link to="/home"> <li>Home</li> </Link>
        <Link to="/amenities"> <li>Amenities</li> </Link>
        <Link to="/updates"> <li>All Updates</li> </Link>
        <Link to="/photos"> <li>Photos</li> </Link>
      </ul>
      <button className="mobile-menu-icon" onClick={() => setIsMobile(!isMobile)}>
        {isMobile ? <i className="fas fa-times"></i> : <i className="fas fa-bars"></i>}
      </button>
    </nav>
  );
}

export default Navbar;
