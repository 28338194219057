import React from 'react';
import './Amenities.css'; // Import the CSS file
import gymimg1 from '../Images/GD-4.JPG';
import gymimg2 from '../Images/GD-8.JPG'; 
import gymimg3 from '../Images/GD-9.JPG';
import parkimg1 from '../Images/GD-1.JPG';
import parkimg2 from '../Images/GD-3.JPG';
import parkimg3 from '../Images/GD-6.JPG';
import parkimg4 from '../Images/GD-7.JPG';
import poolimg1 from '../Images/poolimg1.jpeg';
import poolimg2 from '../Images/poolimg2.jpeg';
import poolimg3 from '../Images/poolimg3.jpeg';
import hallimg1 from '../Images/hallimg1.jpeg';
import hallimg2 from '../Images/hallimg2.jpeg';
import hallimg3 from '../Images/hallimg3.jpeg';

function Amenities() {
    const gym = [gymimg1, gymimg2, gymimg3];
    const park = [parkimg1, parkimg2, parkimg3, parkimg4];
    const pool = [poolimg1, poolimg2, poolimg3];
    const hall = [hallimg1, hallimg2, hallimg3];
    
  
    return (
      <div className="photos-page">
        <header className="page-header">
          <h1>Amenities</h1>
        </header>
  
        <h2 className="event-heading">Parks</h2>
        <div className="photo-grid">
          {park.map((photo, index) => (
            <div className="photo-item" key={index}>
              <img src={photo} alt={`Photo- ${index + 1}`} />
            </div>
          ))}
        </div>
  
        <h2 className="event-heading">Gym</h2>
        <div className="photo-grid">
          {gym.map((photo, index) => (
            <div className="photo-item" key={index}>
              <img src={photo} alt={`Photo- ${index + 1}`} />
            </div>
          ))}
        </div>

        <h2 className="event-heading">Swimming Pool</h2>
        <div className="photo-grid">
          {pool.map((photo, index) => (
            <div className="photo-item" key={index}>
              <img src={photo} alt={`Photo- ${index + 1}`} />
            </div>
          ))}
        </div>
        <h2 className="event-heading">Community Halls</h2>
        <div className="photo-grid">
          {hall.map((photo, index) => (
            <div className="photo-item" key={index}>
              <img src={photo} alt={`Photo- ${index + 1}`} />
            </div>
          ))}
        </div>
      </div>
    );
}

export default Amenities;
