import React from 'react';
import ImageSlider from '../Components/ImageSlider';
import './Home.css'; // Importing the CSS file
import './Updates.css'; // Import the CSS file
// import img1 from "../Images/2.jpg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuildingUser } from '@fortawesome/free-solid-svg-icons'
import { faHouseUser } from '@fortawesome/free-solid-svg-icons'
import { faShop } from '@fortawesome/free-solid-svg-icons'
import { faIndianRupeeSign } from '@fortawesome/free-solid-svg-icons'
import { faUserDoctor } from '@fortawesome/free-solid-svg-icons'
import { faDumbbell } from '@fortawesome/free-solid-svg-icons'
import { faPersonSwimming } from '@fortawesome/free-solid-svg-icons'
import { faBuildingColumns } from '@fortawesome/free-solid-svg-icons'
import { faGopuram } from '@fortawesome/free-solid-svg-icons'

import playground from '../Images/playground.png';
import playtime from '../Images/playtime.png';

import DiwaliMela from '../Images/DiwaliMela.pdf';

function Home() {

  const icons = [
    <FontAwesomeIcon icon={faBuildingUser} style={{ color: "#0c86e4", height: "40px"  }} />,
    <FontAwesomeIcon icon={faHouseUser} style={{ color: "#0c86e4", height: "40px" }} />,
    <FontAwesomeIcon icon={faShop} style={{ color: "#0c86e4", height: "40px" }} />,
    <FontAwesomeIcon icon={faIndianRupeeSign} style={{ color: "#0c86e4", height: "40px" }} />,
    <FontAwesomeIcon icon={faUserDoctor} style={{ color: "#0c86e4", height: "40px" }} />,
    <FontAwesomeIcon icon={faDumbbell} style={{ color: "#0c86e4", height: "40px" }} />,
    <FontAwesomeIcon icon={faPersonSwimming} style={{ color: "#0c86e4", height: "40px" }} />,
    <FontAwesomeIcon icon={faBuildingColumns} style={{ color: "#0c86e4", height: "40px" }} />,
    <FontAwesomeIcon icon={faGopuram} style={{ color: "#0c86e4", height: "40px" }} />,
    <img src={playground} style={{height: "40px"}} alt="In Case nothing loads up refresh."/>,
    <img src={playtime} style={{height: "40px"}} alt="In Case nothing loads up refresh."/>
  ];

  const titles = ["6 blocks from A to F","430 Flats","8 x Commercial Shops", "2 x Banks", "1 x Doctor Clinic", "1 x Gym","1 x Swimming Pool", "2 x Community Halls", "1 x Temple", "Children's Park", "Adult's Playground"]
  return (
    <div className="container">
      
      {/* Left Half */}
      
      {/* Right Half */}
      <div className="rightHalf">
        <h2>Nirala Eden Park</h2>
        <ImageSlider />
      </div>

      <div className="leftHalf">
        <h2>Recent Updates</h2>
        <div className="updateItem">
          <h3><img width="20" height="20" src="https://img.icons8.com/3d-fluency/94/sparkling-1.png" alt="sparkling-1"/>Diwali Mela</h3>
          <p><img width="20" height="20" src="https://img.icons8.com/external-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto/64/external-calender-time-and-date-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto.png" alt="external-calender-time-and-date-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto"/>26th October 2024</p>
          {/* <p>Take a Look at the Celebration of Independence day in the photos section.</p> */}
          <div className="itemDetails">
            {/* <input type="date" className="dateInput" /> */}
            {/* <button className="downloadButton" onClick={() => handleDownload('file1.txt')}>
              Download File
            </button> */}

            <a href={DiwaliMela} download="DiwaliMela"> 
              <button>
                Download File
              </button>
            </a>
          </div>
        </div>
        <div className="updateItem">
          <h3><img width="20" height="20" src="https://img.icons8.com/3d-fluency/94/sparkling-1.png" alt="sparkling-1"/>15th August Celebration</h3>
          <p><img width="20" height="20" src="https://img.icons8.com/external-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto/64/external-calender-time-and-date-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto.png" alt="external-calender-time-and-date-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto"/>18th August 2024</p>
          <p>Take a Look at the Celebration of Independence day in the photos section.</p>
          <div className="itemDetails">
            {/* <input type="date" className="dateInput" /> */}
            <button className="downloadButton" onClick={() => handleDownload('file1.txt')}>
              Download File
            </button>
          </div>
        </div>
        <div className="updateItem">
          <h3><img width="20" height="20" src="https://img.icons8.com/3d-fluency/94/sparkling-1.png" alt="sparkling-1"/>Plastic Collection Drive</h3>
          <p><img width="20" height="20" src="https://img.icons8.com/external-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto/64/external-calender-time-and-date-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto.png" alt="external-calender-time-and-date-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto"/>17th August 2024</p>
          <p>A plastic Collection drive was conducted in the society for reusing plastic. An intiative to protect the environment.</p>
          <div className="itemDetails">
            {/* <input type="date" className="dateInput" /> */}
            {/* <button className="downloadButton" onClick={() => handleDownload('file2.txt')}>
              Download File
            </button> */}
          </div>
        </div>
      </div>

      <div className="updates-page">

      <div>
        </div>
        {/* <section className="page-header">
          <h1>Welcome</h1>
        </section> */}

        <div className="update-card home-title">
          Welcome to Nirala  Eden park.
        </div>
        <br /><br />
        <div className="icon-grid-container">
      {icons.map((icon, index) => (
        <div key={index} className="icon-grid-item">
          {icon}
          <p>{titles[index]}</p>
        </div>
      ))}
    </div>

      </div>
    </div>
  );
}

function handleDownload(filename) {
  // This function can be expanded to download the appropriate file.
  const element = document.createElement("a");
  const file = new Blob(["PDF containing the details of all the events "], {type: 'text/plain'});
  element.href = URL.createObjectURL(file);
  element.download = filename;
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
}

export default Home;
