import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './Components/Navbar';
import Home from './Pages/Home';
import Updates from './Pages/Updates';
import Photos from './Pages/Photos';
import Amenities from './Pages/Amenities';
import Homepage from './Pages/Homepage';

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Homepage />} />
        <Route path="/amenities" element={<Amenities />} />
        <Route path="/updates" element={<Updates />} />
        <Route path="/photos" element={<Photos />} />
      </Routes>
    </Router>
  );
}

export default App;
